<template>
  <v-list nav>
    <template v-for="(item, index) in items">
      <menu-item :key="index" :item="item"/>
    </template>
    <v-list-item>
      <v-btn class="secondary" block @click="redirectLogout">{{ $t('logout') }}</v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  mdiTruckFastOutline,
  mdiParking,
  mdiTruckDelivery,
  mdiTruckOutline,
  mdiAccountMultiple,
  mdiCalendarRange,
  mdiAccount,
  mdiFormatListBulleted,
  mdiHandCoinOutline,
  // mdiFinance,
  mdiImport,
  mdiExport,
  mdiCertificate,
} from '@mdi/js';

export default {
  components: {
    MenuItem: () => import(/* webpackChunkName: "menu" */ './MenuItem.vue'),
  },
  data() {
    return {
      items: [
        {
          title: this.$t('createOrder'),
          icon: mdiTruckFastOutline,
          children: null,
          to: { name: 'createLoadOrder' },
        },
        {
          title: this.$t('betweenDatesList'),
          icon: mdiCalendarRange,
          children: null,
          to: { name: 'listLoadOrders' },
        },
        {
          title: this.$t('parkList'),
          icon: mdiParking,
          children: null,
          to: { name: 'listPark' },
        },
        {
          title: this.$t('entriesList'),
          icon: mdiImport,
          children: null,
          to: { name: 'listParkEntries' },
        },
        {
          title: this.$t('exitsList'),
          icon: mdiExport,
          children: null,
          to: { name: 'listParkExits' },
        },
        {
          title: this.$t('clientList'),
          icon: mdiAccount,
          children: null,
          to: { name: 'listClient' },
        },
        {
          title: this.$t('transportersList'),
          icon: mdiTruckDelivery,
          children: null,
          to: { name: 'listTransporter' },
        },
        {
          title: this.$t('providerList'),
          icon: mdiTruckOutline,
          children: null,
          to: { name: 'listProvider' },
        },
        {
          title: this.$t('agentList'),
          icon: mdiHandCoinOutline,
          children: null,
          to: { name: 'listAgent' },
        },
        {
          title: this.$t('teamsList'),
          icon: mdiAccountMultiple,
          children: null,
          to: { name: 'ListTeams' },
        },
        {
          title: this.$t('globalList'),
          icon: mdiFormatListBulleted,
          children: null,
          to: { name: 'listGlobal' },
        },
        {
          title: this.$t('certifiedMaterial'),
          icon: mdiCertificate,
          children: null,
          to: { name: 'certifiedMaterial' },
        },
        // TODO: Develop this menu
        /* {
          title: this.$t('clientAnalysis'),
          icon: mdiFinance,
          children: null,
          to: { name: 'clientAnalysis' },
        }, */
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'roles']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async redirectLogout() {
      await this.logout();

      this.$router.replace({ name: 'login' });
    },
  },
};
</script>
