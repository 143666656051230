<template>
  <!-- eslint-disable max-len -->
  <v-dialog :value="changelogDialog" persistent width="800px">
    <v-card>
      <v-card-title class="info white--text">
        <span class="text-h5">{{ $t('changelog') }}</span>
      </v-card-title>
      <v-card-text class="mt-3">
        <h2>Versão 1.1.1 - 05/02/2023</h2>
        <div>
          <ul>
            <li>Corrigir a ligação entre as Matrículas e os Transportadores;</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.1.0 - 05/02/2023</h2>
        <div>
          <ul>
            <li>No ecrã de "Nova Carga" alterada a designação do campo "Mata alternativa" para "Código";</li>
            <li> Na "Listagem Equipas" adicionado Filtro no cabeçalho da Coluna "Morada de Carga";</li>
            <li>Na "Listagem Material Certificado" adicionado Filtro no cabeçalho da Coluna "Certificação";</li>
            <li>Alinhado o Carimbo "SURE" na Nota de Entrega mais para a esquerda de forma a evitar sobreposição.</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.9 - 07/12/2023</h2>
        <div>
          <ul>
            <li>Atualizada a numereção das Notas de Entrega para considerar o "Ano";</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.8 - 07/12/2023</h2>
        <div>
          <ul>
            <li>Atualizado o campo "Nº FT" para Alfanumérico;</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.7 - 28/11/2023</h2>
        <div>
          <ul>
            <li>Associado corretamente a Fatura à respectiva Equipa na Listagem Equipas;</li>
            <li>Alterado o campo "Nº FT (Floresta e Transitário) para "Campo editável";</li>
            <li>Atualizado o Filtro "Faturado" para considerar a série "Fatura";</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.6 - 21/11/2023</h2>
        <div>
          <ul>
            <li>Na Listagem Intermediário ao Filtrar o "Intermediário" mostrar apenas os dados correspondentes à opção selecionada (Listagem + PDF + Excel).</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.5 - 16/11/2023</h2>
        <div>
          <ul>
            <li>Atualizada a Pesquisa pelo campo "Mata";</li>
            <li>Corrigido o erro ao Gerar o PDF com Filtro do campo "Mata" ativo.</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.4 - 15/11/2023</h2>
        <div>
          <ul>
            <li>Atualizadas as condições de pesquisa do Filtro das Listagem Intermediário.</li>
            <li>Filtro passa a considerar o "Intermediário 2" e "Intermediário 3"</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.3 - 17/10/2023</h2>
        <div>
          <ul>
            <li>Atualizado o Valor por defeito do campo "Faturado" na Listagem Equipas;</li>
            <li>Realinhados Valores Totais no final das Listagens;</li>
            <li>Recalculados os Valores Totais automaticamente após atualizar valores nas linhas;</li>
            <li>Reoordenados os Campos na apresentação das Listagens;</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.2 - 16/10/2023</h2>
        <div>
          <ul>
            <li>Atualizadas as Fórmulas de cálculo dos valores "Totais", "Lucro" e "Final" para contemplar o "Intermediário 2" e "Intermediário 3"</li>
          </ul>
          <br/>
        </div>
        <h2>Versão 1.0.1 - 15/10/2023</h2>
        <div>
          <ul>
            <li>Atualizado o Filtro do "Intermediário" para passar a considerar "Nome/Abreviatura"</li>
          </ul>
          <br/>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="setChangelogDialog(false)">{{ $t('aClose') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters(['changelogDialog']),
  },
  methods: {
    ...mapMutations(['setChangelogDialog']),
  },
};
</script>
